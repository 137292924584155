import './styles.css'
import {useEffect, useState} from "react";
import {createSearchParams, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SaasBox from "../../../Components/SaasBox";
import SaasHeader from "../../../Components/SaasHeader";
import {useCreateSessionMutation} from "../../../app/api/spacesApi";
import { toast } from 'react-toastify';
import useRoleRedirect from "../../../hooks/useRoleRedirect";
import NewSpaceList from "./NewSpaces/List";
import {Helmet} from "react-helmet-async";

const MySpaces = () => {

    useRoleRedirect('Space Owner')

    const navigate = useNavigate();
    const [createSession, {isLoading} ] = useCreateSessionMutation()
    const [view, setView] = useState( 'table')

    useEffect(() => {
        localStorage.setItem('spaces-view', JSON.stringify(view))
    }, [view]);

    const newSpace =  async () => {
        const response = await createSession({});
        if (response.error)
        {
            toast.error("Sorry we could not create new space")
        }

        if (response.data)
        {
            navigate({
                pathname: "create",
                search: createSearchParams({
                    session: response.data?.token
                }).toString()
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Cohesii | My Spaces</title>
            </Helmet>
            <SaasHeader title={"My Spaces"} view={view} setView={setView} add={true} >
                <button disabled={isLoading} type={'button'} className={'btn__auth_primary small !mt-0'} onClick={() => newSpace()}>
                    <FontAwesomeIcon icon="fa-solid fa-plus"/> Add Space
                </button>
            </SaasHeader>
            <SaasBox className={'mt-4'}>
                <NewSpaceList />
            </SaasBox>
        </>
    )


}

export default MySpaces