import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

const UploadComponent = ({images, setImages}) => {

    const [errors, setErrors] = useState([]);

    const validateImage = (file, callback) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            if (img.width >= 1000 && img.height >= 1000) {
                callback(true);
            } else {
                callback(false);
            }
        };
    };

    const handleFiles = (files) => {
        const validImages = [];
        const newErrors = [];

        Array.from(files).forEach((file) => {
            validateImage(file, (isValid) => {
                if (isValid) {
                    validImages.push(file);
                } else {
                    newErrors.push(`Image ${file.name} is too small.`);
                }

                // Update state after all images are processed
                if (validImages.length + newErrors.length === files.length) {
                    if (validImages.length > 0) {
                        setImages((prevImages) => [...prevImages, ...validImages]);
                    }
                    setErrors(newErrors);
                }
            });
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFiles(e.dataTransfer.files);
    };

    const handleBrowse = (e) => {
        handleFiles(e.target.files);
    };

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    return (
        <>
            <div className={'flex flex-col gap-4 flex-1'}>
                <div className={"new-space__subheader"}>
                    <h2 className={'new-space__subsection_title'}>Upload</h2>
                </div>
                <div className={'new-space__subsection_content !mt-3 !mb-8'}>
                    <p>Requirements:</p>
                    <ul>
                        <li>High Resolution originals - No Stock photos</li>
                        <li>Colour Images - No black and white</li>
                        <li>Must not include people - Unless signed GDPR consent.</li>
                    </ul>
                </div>


                <div
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                    className="max-w-xl"
                >
                    <label
                        className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                        <span className="flex items-center space-x-2">
                            <FontAwesomeIcon icon="fa-solid fa-upload"/>
                            <span className="font-medium text-gray-600">
                                Drop files to Attach, or
                                <span className="text-blue-600 underline pl-2">browse</span>
                            </span>
                        </span>
                        <input
                            type="file"
                            name="file_upload"
                            className="hidden"
                            accept="image/*"
                            multiple
                            onChange={handleBrowse}
                        />
                    </label>
                </div>
            </div>
            <div className="mt-4 flex flex-1 flex-col">

                {images.length > 0 && (
                    <div className={"new-space__subheader"}>
                        <h2 className={'new-space__subsection_title'}>Gallery</h2>
                    </div>
                )}

                {errors.length > 0 && (
                    <div className="text-red-600">
                        {errors.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </div>
                )}


                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">


                    {images.map((image, index) => (
                        <div key={index} className="relative">
                            <img
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index}`}
                                className="w-full h-32 object-cover rounded"
                            />
                            <button
                                onClick={() => removeImage(index)}
                                className="absolute top-2 right-2 bg-red-300 text-white rounded-full p-1 w-[30px]"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-trash-can" className={'size-3 text-white'} />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default UploadComponent