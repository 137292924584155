import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StepList =({completedSteps, setActiveStep}) => {

    const user = useSelector((state) => state.auth.user);

    const findNextIncompleteStep = () => {
        return Object.keys(completedSteps).find((key) => !completedSteps[key].completed);
    };

    return (
        <>
            <h1 className={'text-3xl font-bold font-montserrat text-gray-800 dark:text-gray-50 mb-4'}>{user?.name}, lets get your space public. </h1>
            <p className={"mb-10 text-sm text-gray-500"}>Work through each of the steps, the more information you provide the better.</p>
            <div className={'new-space__steps'}>
                {Object.values(completedSteps).map((step) => (
                    <div key={step.key} className={`step border-t-gray-100 border-b-gray-100`}>
                        <div className={'description'}>
                            {!step.completed && <div className={'circle'}></div> }
                            {step.completed && <FontAwesomeIcon icon="fa-solid fa-circle-check" className={'circle !border-0 text-green-700'} /> }
                            <div>
                                <h4 className="title">{step.key}. {step.title}</h4>
                                <p className="text-gray-500 text-sm">{step.description}</p>
                            </div>
                        </div>
                        {step.completed ? (
                            <p onClick={() => setActiveStep(step.key)} className="text-blaze-500 font-medium cursor-pointer hover:text-blaze-700">
                                Edit <FontAwesomeIcon icon="fa-solid fa-pen-to-square" className={'ml-2'} />
                            </p>
                        ) : (
                            step.key === Number(findNextIncompleteStep()) && (
                                <button
                                    className="btn__auth_primary"
                                    onClick={() => setActiveStep(step.key)}
                                >
                                    Start
                                </button>
                            )
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default StepList