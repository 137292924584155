import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL + '/user/',
    credentials: 'include', // Important to include cookies
    prepareHeaders: (headers,  {getState}) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        return headers;
    },
});


export const userApi = createApi({
        reducerPath: 'userApi',
        baseQuery: baseQuery,
        endpoints: (builder) => ({
            getMe:
                builder.mutation({
                    query:() => ({
                        url: `me`,
                        method: 'GET',
                    }),

                    transformResponse: (response) => response.data
                })
    })
})

export const {
    useGetMeMutation,
} = userApi