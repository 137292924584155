import React, {useState} from "react";

import {StepFooter} from "./NewSpace";
import {toast} from "react-toastify";
import uploadImage from "../../app/helpers/upload";
import {useUpdateSessionMutation} from "../../app/api/spacesApi";
import UploadComponent from "../Upload";


const PhotoComponent = ({ setActiveStep, session, markStepAsCompleted, defaultData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);


    const [updateSession, {isLoading: updateSessionLoading}] = useUpdateSessionMutation();


    const upload = async (e) => {
        e.preventDefault();

        if (images.length < 4) {
            toast.error("You need to upload at least 4 images.");
            return;
        }

        setIsLoading(true);
        let successful = []
        try {
            for (let i = 0; i < images.length; i++) {
                const file = images[i];
                successful.push(await uploadImage("cohesii-staging-laravel", file)); // Upload each image
                toast.success(`Successfully uploaded ${file.name}`);
            }

        } catch (err) {
            toast.error(`Error uploading files: ${err}`);
        }

        if (!updateSessionLoading && successful.length >= 4) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 3,
                    "data": {"photos": successful}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                setIsLoading(false);
                toast.success("All Space photos updated successfully")
                markStepAsCompleted(3)
                setActiveStep(null)
                return true;
            }
        }

        setIsLoading(false);
        return false;

    };

    return (
        <div className={'new-space__location'}>

            <div className={'new-space__header'}>
                <h1 className={'title'}>Upload photos of your space</h1>
                <p className={"strapline"}>Encourage new guests and give them the best shot. We
                    recommend at least 6 high quality images..</p>
            </div>


            <div className={"flex flex-col lg:flex-row gap-4"}>


                <UploadComponent images={images} setImages={setImages}/>
            </div>
            <StepFooter
                setActiveStep={setActiveStep}
                next={(e) => upload(e)}
                isLoading={isLoading}
            />
        </div>
    );
};

export default PhotoComponent