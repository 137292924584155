import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {logout, setUser} from "../app/features/auth/authSlice";
import {useGetMeMutation} from "../app/api/userApi";

const useFetchUser = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getMe] = useGetMeMutation(); // We don't need to track isLoading from getMe

    const user = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = useSelector((state) => state.auth.token);

    // Only fetch user data once, and set loading accordingly
    useEffect(() => {
        const fetchUser = async () => {
            try {
                if (!user?.id && token) {
                    const { data, error } = await getMe({});
                    if (error) {
                        throw new Error("Failed to fetch user");
                    }
                    // Dispatch user data only once after fetching
                    dispatch(setUser(data));
                }
            } catch (error) {
                dispatch(logout()); // Logout if there's an error
            } finally {
                setLoading(false); // Stop loading when done
            }
        };

        if (!user?.id && token) { // Only fetch if the user is not already fetched
            fetchUser();
        } else {
            setLoading(false); // Stop loading if user is already available
        }
    }, [dispatch, getMe, token, user?.id]);

    // Redirect logic based on authentication state and current path
    useEffect(() => {
        if (loading) return; // Prevent redirect until loading is complete

        const currentPath = window.location.pathname;

        if (isAuthenticated && user?.id) {
            if (currentPath.startsWith("/auth")) {
                navigate("/"); // Redirect to dashboard if user is logged in and on an auth page
            }
        } else {
            if (!currentPath.startsWith("/auth")) {
                navigate("/auth"); // Redirect to login if not authenticated and not already on an auth page
            }
        }
    }, [isAuthenticated, user?.id, navigate, loading]);

    return loading; // Expose loading state to control UI (e.g., showing a loader)
};

export default useFetchUser;