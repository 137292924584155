import './styles.css'
import {useEffect, useState} from "react";
import {TwoFactor} from "../TwoFactor/TwoFactorView";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import {StepOne} from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {useRegisterMutation} from "../../app/api/authApi";
import {loginSuccess} from "../../app/features/auth/authSlice";
import Loading from "../../Components/Loading";
import {Helmet} from "react-helmet-async";

const RegisterView = () => {

    const [registerTrigger, setRegisterTrigger] = useState(false);

    const [register, {isLoading}] = useRegisterMutation();

    const [searchParams] = useSearchParams();

    const location = useLocation();

    const dispatch = useDispatch();

    const savedData = JSON.parse(localStorage.getItem("register"));

    const initialState = {
        'role': savedData?.role ?? searchParams?.role ?? null,
        'firstName': savedData?.firstName ?? null,
        'lastName': savedData?.lastName ?? null,
        'email': savedData?.email ?? null,
        'company': savedData?.company ?? null,
        'password': null,
        'confirmPassword': null,
    }

    const [multiStepData, setMultiStepData] = useState(initialState)

    const setMultiStepDataByKey = (key, value) => {
        setMultiStepData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const needsTwoFactor = useSelector((state) => state.auth.needsTwoFactor);

    const [formError, setFormError] = useState(location?.state?.formError ?? null) ;


    const [step, setStep] = useState(1);


    useEffect(() => {
        localStorage.setItem("register", JSON.stringify(multiStepData))
    }, [multiStepData]);

    useEffect(() => {
        if (registerTrigger === true)
        {
            const registerAction = async () => {
                const response = await register({
                    data: {
                        "email": multiStepData.email,
                        "role": multiStepData.role,
                        "password": multiStepData.password,
                        "password_confirmation": multiStepData.confirmPassword,
                        "firstName": multiStepData.firstName,
                        "lastName": multiStepData.lastName,
                        "company": multiStepData.company
                    }
                })

                if (response.error) {
                    setFormError(response.error?.data?.message)
                    setStep(2)
                    setRegisterTrigger(false)
                    return;
                }

                if (response.data?.token) {
                    setMultiStepData({})
                    setStep(4)
                    dispatch(loginSuccess(response.data))
                    localStorage.removeItem("register")
                }
            }
            registerAction().then(() => setRegisterTrigger(false))
        }
    }, [registerTrigger])

    return (
        <>
            <Helmet>
                <title>Cohesii | Register to the portal</title>
            </Helmet>
            <section className={'register-container'}>
                <div className={'register relative'}>
                    <div className={'steps'}>
                        <div className={`step ${step > 1 ? 'complete' : ''}`}></div>
                        <div className={`step ${step > 2 ? 'complete' : ''}`}></div>
                        <div className={`step ${step > 3 ? 'complete' : ''}`}></div>
                        <div className={`step ${step > 4 ? 'complete' : ''}`}></div>
                    </div>


                    {registerTrigger && (
                       <Loading isLoading={isLoading} />
                    )}

                    {step === 1 && <StepOne
                        registerError={formError}
                        setStep={setStep}
                        setRegisterError={setFormError}
                        setRegisterData={setMultiStepDataByKey}
                    />}

                    {step === 2 && <StepTwo
                        registerError={formError}
                        setStep={setStep}
                        setRegisterError={setFormError}
                        multiStepData={multiStepData}
                        setRegisterData={setMultiStepDataByKey}
                    />}

                    {step === 3 && <StepThree
                        registerError={formError}
                        setStep={setStep}
                        setRegisterError={setFormError}
                        setRegister={setRegisterTrigger}
                        setRegisterData={setMultiStepDataByKey}
                    />}

                    {needsTwoFactor && step === 4 && <TwoFactor />}
                </div>
            </section>

        </>
    )
}

export default RegisterView