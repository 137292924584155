import RegisterImage from './registerRole.png'
import {Form, Link} from "react-router-dom";
import SocialConnect from "../Login/SocialConnect";
import Separator from "../../Components/Seperator/Seperator";
import {useState} from "react";
import {validateEmail, validateRequired} from "../../Components/Forms/validation";

export const StepTwo = ({registerError, setRegisterError,multiStepData,  setRegisterData, setStep}) => {


    const [formState, setFormState] = useState({
        firstName: multiStepData?.firstName ?? '',
        lastName: multiStepData?.lastName ?? '',
        email: multiStepData?.email ?? '',
        company: multiStepData?.company ?? '',
        errors: {
            firstName: '',
            lastName: '',
            email: '',
            company: '',
        },
    });

    // Function to handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        let error = '';

        // Validate the specific field based on its name
        if (!validateRequired(value) && name !== 'email') {
            error = `${name.replace(/([A-Z])/g, ' $1')} is required.`;
        } else if (name === 'email' && !validateEmail(value)) {
            error = 'Valid email is required.';
        }

        // Update the form state, including the specific field's error
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
            errors: { ...prevState.errors, [name]: error },
        }));
    };

    // Validation Logic
    const validateForm = () => {
        const newErrors = {};
        if (!validateRequired(formState.firstName)) newErrors.firstName = "First name is required.";
        if (!validateRequired(formState.lastName)) newErrors.lastName = "Last name is required.";
        if (!validateRequired(formState.company)) newErrors.company = "Company is required.";
        if (!validateRequired(formState.email) && !validateEmail(formState.email)) {
            newErrors.email = "Valid email is required.";
        }
        return newErrors;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setFormState((prevState) => ({
                ...prevState,
                errors: validationErrors,
            }));
        } else {
            // No errors, submit data
            setRegisterData("firstName", formState.firstName);
            setRegisterData("lastName", formState.lastName);
            setRegisterData("email", formState.email);
            setRegisterData("company", formState.company);
            setStep(3)
        }
    };

    return (
        <>
            <h1 className="auth__title">Create Account</h1>

            {registerError && (
                <p className="rounded-md text-red-500 text-md bg-red-50 border-red-100 border p-4 mt-6 mb-3">
                    {registerError}
                </p>
            )}

            <SocialConnect action="register" />
            <Separator text="or" />

            <Form onSubmit={onSubmit} id="registerFormStepTwo" className="register-form__step_two">
                <fieldset className="input__fieldset">
                    <div className="input__fieldset group name">
                        <div className="input__field">
                            <label className="input__field_label large" htmlFor="firstName">First Name *</label>
                            <input
                                className={`input__field_input ${formState.errors.firstName ? "error" : ""}`}
                                type="text"
                                name="firstName"
                                value={formState.firstName}
                                onChange={handleChange}
                                placeholder="First name *"
                                id="firstName"
                            />
                            {formState.errors.firstName && (
                                <p className="text-red-500 text-sm mt-1">{formState.errors.firstName}</p>
                            )}
                        </div>
                        <div className="input__field">
                            <label className="input__field_label large" htmlFor="lastName">Last Name *</label>
                            <input
                                className={`input__field_input ${formState.errors.lastName ? "error" : ""}`}
                                type="text"
                                name="lastName"
                                value={formState.lastName}
                                onChange={handleChange}
                                placeholder="Last name *"
                                id="lastName"
                            />
                            {formState.errors.lastName && (
                                <p className="text-red-500 text-sm mt-1">{formState.errors.lastName}</p>
                            )}
                        </div>
                    </div>
                    <div className="input__field email">
                        <label className="input__field_label large" htmlFor="email">Email *</label>
                        <input
                            className={`input__field_input ${formState.errors.email ? "error" : ""}`}
                            type="email"
                            name="email"
                            value={formState.email}
                            onChange={handleChange}
                            placeholder="Enter your email *"
                            id="email"
                        />
                        {formState.errors.email && (
                            <p className="text-red-500 text-sm mt-1">{formState.errors.email}</p>
                        )}
                    </div>
                    <div className="input__field">
                        <label className="input__field_label large" htmlFor="company">Company *</label>
                        <input
                            className={`input__field_input ${formState.errors.company ? "error" : ""}`}
                            type="text"
                            name="company"
                            value={formState.company}
                            onChange={handleChange}
                            placeholder="Company *"
                            id="company"
                        />
                        {formState.errors.company && (
                            <p className="text-red-500 text-sm mt-1">{formState.errors.company}</p>
                        )}
                    </div>
                </fieldset>

                <div className={'flex flex-row gap-3'}>
                    <button type="button" onClick={() => setStep(1)} className="btn__auth_back ">
                        Back
                    </button>
                    <button type="submit" className="btn__auth_primary">
                        Continue
                    </button>
                </div>
            </Form>

            <p className="auth-footer_links">
                Already have an account?{' '}
                <Link to="/auth/login" title="Cohesii Login">
                    Login
                </Link>
            </p>
        </>
    );
};

export default StepTwo