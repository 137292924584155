const SaasBox = ({className,children }) => {

    return (
        <div
            className={`${className} rounded-sm border border-stroke bg-white px-5 pt-6 pb-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7`}>
            {children}
        </div>
    )
}

export default SaasBox