import PinInput from "react-pin-input";
import {useState} from "react";

import './styles.css'
import {useTwoFactorConfirmMutation} from "../../app/api/authApi";
import {setUser, twoFactorSuccess} from "../../app/features/auth/authSlice";
import {useDispatch} from "react-redux";
import { useNavigate} from "react-router-dom";
import {useGetMeMutation} from "../../app/api/userApi";

const TwoFactorForm = ({setFormError, backAction = () => {}}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState("")

    const [twoFactorConfirm] = useTwoFactorConfirmMutation()
    const [getMe] = useGetMeMutation()

    const confirm = async () => {

       const response =  await twoFactorConfirm({code: value});
        if (response.error) {
            setFormError(response.error?.data?.message)
            return;
        }

        const user = await getMe({});

        if (user.error) {
            setFormError("Could not find your user.")
            backAction()
        }else {
            dispatch(twoFactorSuccess())
            dispatch(setUser(user))
            navigate('/')
        }
    }

    return (
        <div className={'two-factor-form'}>

            <p className={'resend'}>Did not receive an email?
                <button type={"button"} onClick={(e) => e.preventDefault()}>Click Here</button>
            </p>

            <PinInput
                length={6}
                onComplete={(value) => setValue(value)}
                type="custom"
                focus
                secret
                secretDelay={500}
                inputStyle={{

                    border: "2px solid lightgray", // Customize the border color
                    borderRadius: "8px", // Adjust border radius
                    fontSize: "18px", // Change font size
                    padding: "10px", // Add some padding
                    margin: "5px", // Adjust margin
                }}
            />


            <div className={'flex flex-row gap-[30px] mt-4'}>

                {backAction && <button onClick={backAction} type="button" id={'back'} name={'back'} className={'btn__auth_back'}>
                    Back
                </button> }
                <button onClick={confirm} type="button" id={'confirm'} name={'confirm'} className={'btn__auth_primary'}>
                    Confirm
                </button>
            </div>
        </div>
    )

}

export default TwoFactorForm