import React, {useEffect, useRef} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import Logo from '../../assets/cohesii-white.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './styles.css'
import {useSelector} from "react-redux";
import ClickOutside from "../ClickOutside";

const Sidebar = ({sidebarOpen = false, setSidebarOpen}) => {

    const location = useLocation();
    const { pathname } = location;

    const sidebar = useRef(null);
    const trigger = useRef(null);

    const userRoles = useSelector((state) => state.auth.user?.roles);


    return (
        <ClickOutside onClick={() => setSidebarOpen(false)} className="sidebar">
            <aside
                ref={sidebar}
                className={`absolute left-0 top-0 z-9999 flex h-screen w-[18rem] flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                }`}
            >

                <div className="flex items-center justify-between gap-2 px-6 py-5 lg:py-6.5">
                    <NavLink to="/">
                        <img src={Logo} alt="Cohesii orange gradient" title={"Cohesii Portal"}
                             className={"w-full max-w-[200px] object-cover"}/>
                    </NavLink>

                    <button
                        ref={trigger}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                        className="block lg:hidden"
                    >
                        <FontAwesomeIcon icon={'fas fa-xmark'} className={"size-6 text-white"}/>
                    </button>



                </div>
                <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                    <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">

                        <div>
                            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
                                MENU
                            </h3>

                            <ul className="mb-6 flex flex-col gap-1.5">
                                <li>
                                    <NavLink
                                        to="/dashboard"
                                        className={`group sidebar__link ${
                                            pathname.includes('dashboard') && 'active'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-gauge" />
                                        Dashboard
                                    </NavLink>
                                </li>

                                {userRoles?.includes('Space Owner') && <li>
                                    <NavLink
                                        to="/my-spaces"
                                        className={`group sidebar__link ${
                                            pathname.includes('my-spaces') && 'active'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-building" />
                                        My Spaces
                                    </NavLink>
                                </li> }
                            </ul>

                        </div>
                    </nav>
                </div>
            </aside>
        </ClickOutside>
)


}

export default Sidebar