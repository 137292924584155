import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../../../Components/Modal/modal-context";
import {useDeleteSessionMutation} from "../../../app/api/spacesApi";
import {toast} from "react-toastify";
import Loading from "../../../Components/Loading";

const DeleteSpace = ({
                         hashId,
                         completeCallback = () => {},
                         errorCallback = () => {},
}) => {
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const { unSetModal } = useModal()

    const [deleteSession, {isLoading }] = useDeleteSessionMutation();

    const doDelete = async () => {
        if (!isLoading) {
            const response = await deleteSession({session: hashId})

            if (response.error) {
                toast.error(response.error.message)
                errorCallback()
                return false;
            }

            if (response.data?.success) {
                toast.success("Space deleted successfully")
                completeCallback()
                unSetModal()
            }

        }

    }

    return (
        <div className={'delete__space'}>
            <h2 className={'section__title'}>Delete Space</h2>
            <p className={'section__strap'}>Are you sure you want to delete space?</p>

            <Loading isLoading={isLoading} />

            {!isLoading && (
                <>
                    <label className={'flex gap-3 text-gray-500 dark:text-gray-100 text-sm'} htmlFor={'confirmDelete'}>
                        <input type={'checkbox'} name={'confirmDelete'} id={'confirmDelete'}
                               onChange={(e) => setDeleteDisabled(!e.target.checked)}/>
                        <span>Selecting this checkbox confirms you understand that deleting this entry will delete all content relating to this space.</span>
                    </label>

                    <button onClick={doDelete} disabled={deleteDisabled} type={'button'} className={'btn__auth_primary w-auto !flex-0'}>
                        Delete
                    </button>

                    <button onClick={unSetModal} type={'button'} className={'btn__auth_back w-auto !flex-0'}>
                        Cancel
                    </button>
                </>
            )}

        </div>
    )


}

export default DeleteSpace