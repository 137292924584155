import { createSlice } from '@reduxjs/toolkit';
import { setToken, removeToken, getToken } from '../../utils/tokenStorage';

const initialState = {
    user: null,
    token: getToken(),
    isAuthenticated: !!getToken(),
    needsTwoFactor: false,
    status: 'idle',
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        twoFactorBack: (state, action) => {
            state.token = null;
            state.needsTwoFactor = false;
        },
        twoFactorSuccess: (state, action) => {
            state.isAuthenticated = true;
            state.needsTwoFactor = false;
            state.status = 'success';
            setToken(state.token);
        },
        loginSuccess: (state, action) => {
            state.token = action.payload.token;
            state.isAuthenticated = false;
            state.needsTwoFactor = true;
            state.status = 'twoFactor';
        },
        logout: (state) => {
            state.token = null;
            state.user = null;
            state.isAuthenticated = false;
            state.status = 'idle';
            removeToken();
        },
        setAuthenticated: (state, action) => {
            state.isAuthenticated = true;
            state.needsTwoFactor = false;
            state.status = 'success';
        },
    },
    extraReducers: (builder) => {

    },
});

export const {
    loginSuccess,
    logout,
    setAuthenticated ,
    twoFactorSuccess,
    twoFactorBack,
    setUser,
} = authSlice.actions;

export default authSlice.reducer;