import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const SaasHeader = ({title, view, setView, add = false, children }) => {

    return (
        <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
            <h2
                className="font-montserrat font-semibold text-black dark:text-white text-lg">
                {title}
            </h2>
            <div className={'flex flex-row gap-2'}>
                {view && setView && (
                    <>
                        {view === 'grid' &&
                            <button type={"button"} onClick={() => setView('table')}><FontAwesomeIcon icon="fa-solid fa-table"/>
                            </button>}
                        {view === 'table' && <button type={"button"} onClick={() => setView('grid')}><FontAwesomeIcon
                            icon="fa-solid fa-table-cells-large"/></button>}
                    </>
                )}

                {children}
            </div>
        </div>
    )
}

export default SaasHeader