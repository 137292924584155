import {configureStore, createAsyncThunk} from '@reduxjs/toolkit'
import {authApi} from "./api/authApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import authReducer from './features/auth/authSlice';
import {userApi} from "./api/userApi";
import {spacesApi} from "./api/spacesApi";

export const fetchCsrfToken = createAsyncThunk('csrf/fetchCsrfToken', async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`, {
        credentials: 'include',
    });
    return response.ok;
});


export const store = configureStore({
    reducer: {
        auth: authReducer,
        // Add the generated reducer as a specific top-level slice
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [spacesApi.reducerPath]: spacesApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(spacesApi.middleware)
            .concat(userApi.middleware),
})

setupListeners(store.dispatch)