import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useSsoCallbackMutation} from "../app/api/authApi";
import {loginSuccess} from "../app/features/auth/authSlice";
import Loading from "../Components/Loading";

const SsoCallback = () => {

    const [isLoading, setLoading] = useState(false);

    // create the dispatch hook
    const dispatch  = useDispatch();
    const navigate  =  useNavigate();

    // get the provider and search from the URL
    const { provider } = useParams();

    //get the search query
    const { search } = useLocation();

    const [ssoCallback] = useSsoCallbackMutation()

    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) return;
        setLoading(true);
        hasRun.current = true;

        const doCallback = async () => {
            const response =  await ssoCallback({search, provider})
            setLoading(false);
            if (response.error) {

                navigate('/auth/login',{state:{formError: response.error?.data?.message}})
                return;
            }

            if (response.data?.token) {
                dispatch(loginSuccess(response.data))
                navigate('/auth/2fa');
            }
        }

        doCallback()

    }, [dispatch, provider, search]);

    return  <Loading isLoading={isLoading} />
}

export default SsoCallback