import React, {useEffect, useState} from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Outlet} from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import useFetchUser from "./hooks/useFetchUser";
import {ModalProvider} from "./Components/Modal/modal-context";

function App() {

    const loading = useFetchUser()

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (

            <ModalProvider>
                <div className="App">
                    <div className="dark:bg-boxdark-2 dark:text-bodydark">
                        <div className="flex h-screen overflow-hidden">
                            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}  />

                            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">

                                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}  />
                                {!loading &&
                                    <main>
                                        <div className={'mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'}>
                                            <Outlet/>
                                        </div>
                                    </main>
                                }

                                {loading && <>Loading ...</>}

                                <footer id={"footer"}>

                                </footer>
                            </div>
                        </div>


                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick

                    />
                </div>
            </ModalProvider>
    );
}

export default App;
